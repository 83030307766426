<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="row mb-4">
                    <div class="d-flex justify-content-between">

                        <h6 class="fw-bold">Dashboard</h6>

                        <div class="d-flex align-items-center ms-auto gap-2">
                            <div class="form-group">
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="periode" class="picker" :editable="true"
                                        :clearable="false" @update:modelValue="dashboard_index" />
                                    <a class="addonset">
                                        <i class='bx bx-calendar'></i>
                                    </a>
                                </div>
                            </div>

                            <div class="form-group">

                                <vue-select :options="branchList"
                                    :settings="{ settingOption: value, settingOption: value }" 
                                    v-model="filter"
                                    placeholder="None" 
                                    @select="dashboard_index" style="width: 200px; ">
                                </vue-select>
                            </div>
                            
                            
                            
                            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- chart waiting Approval -->
                    
                    <div class="col-12 col-lg-10">
                        <div class="row ">

                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D; background-color: #09B67D; color: white;">
                                    
                                    <h4 class="mb-1 fw-bold" style="color: white;">{{ this.absensiList.waiting_approval }}</h4>
                                    <small @click="goToApproval()" style="color: white;">Waiting Approval</small>
                                    
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">
                                        <h4 class="mb-1 text-black fw-bold">{{ this.companyList.total_branch }}</h4>
                                        <img src="@/assets/img/buliding.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>All Branch</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">
                                        <h4 class="mb-1 text-black fw-bold">{{ this.companyList.total_employee }}</h4>
                                        <img src="@/assets/img/profile-2user.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Total Employee</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">  
                                        <h4 class="mb-1 text-black fw-bold">{{ this.absensiList.outside_visit }}</h4>
                                        <img src="@/assets/img/buildings.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Outside Visit</small>
                                </div>
                            </div>

                            <!-- second row -->
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">  
                                        <h4 class="mb-1 text-black fw-bold">{{ this.absensiList.present }}</h4>
                                        <img src="@/assets/img/user-tick.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Present</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">  
                                        <h4 class="mb-1 text-black fw-bold">{{ this.absensiList.late }}</h4>
                                        <img src="@/assets/img/user-remove.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Late</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">  
                                        <h4 class="mb-1 text-black fw-bold">{{ this.absensiList.leave }}</h4>
                                        <img src="@/assets/img/document-previous.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Leave</small>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <div class="d-flex justify-content-between align-items-start">   
                                        <h4 class="mb-1 text-black fw-bold">{{ this.absensiList.annual_leave }}</h4>
                                        <img src="@/assets/img/export.png" alt="icon" style="width: 20px; height: 20px; ">
                                    </div>
                                    <small>Annual Leave</small>
                                </div>
                            </div>

                            <!-- Other Cards -->
                            <!-- <div class="col-md-3" v-for="(item, index) in stats" :key="index">
                                <div class="card text-black p-3 rounded-3" style="border: 1px solid #09B67D;" >
                                    <h4 class="mb-1 text-black fw-bold">{{ item.value }}</h4>
                                    <small>{{ item.label }}</small>
                                </div>
                            </div> -->
                        </div>


                    </div>
                    <!-- Gender Diversity -->
                    <div class="col-md-2">
                        <div class="card p-3" style="height: 206px; border: 1px solid #09B67D;">
                            <p>Gender Diversity</p>
                            
                            <div class="" style="position: relative; height: 130px; width: 100%;">
                                <div class="chartjs-wrapper-demo ">
                                    <vue3-chart-js 
                                        
                                        :key="idGenderChart"
                                        class="chartjs-chart"
                                        v-bind="{ ...genderChart }"
                                    ></vue3-chart-js>
                                </div>
                                <!-- <canvas ref="genderChart"></canvas> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row">

                            <div class="col-md-4">
                                <div class="card p-3" style=" border: 1px solid #09B67D; height: 248px;">
                                    <p class="fw-bold">Job Level</p>
                                    <b-progress class="mt-1 rounded-5" style="height: 25px; background-color: #f4f4f4;">
                                        <!-- <b-progress-bar
                                            v-for="job in jobLevelsWithPercentage"
                                            :key="job.name"
                                            :value="job.percentage"
                                            :style="{ backgroundColor: job.color }"
                                        ></b-progress-bar> -->
                                        <b-progress-bar
                                            v-for="job in jobLevelsWithPercentage"
                                            :key="job.name"
                                            :value="job.percentage"
                                            :style="{ backgroundColor: job.color }"
                                        ></b-progress-bar>
                                    </b-progress>
                                    <div class="d-flex justify-content-between ">
                                        <p>0%</p>
                                        <p>100%</p>
                                    </div>
                                    <div class="">
                                        <div v-for="job in jobLevelsWithPercentage" :key="job.name" class="d-flex justify-content-between">
                                            <span>
                                            <span class="legend-box" :style="{ backgroundColor: job.color }"></span>
                                            {{ job.name }}
                                            </span>
                                            <span>{{ job.count }} ({{ job.percentage.toFixed(2) }}%)</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="col-md-4">
                                <div class="card p-3" style=" border: 1px solid #09B67D;">
                                    <p class="fw-bold">Length of Service</p>
                                    
                                    <div class="chartjs-wrapper-demo" style="margin-bottom: 25px;">
                                        <vue3-chart-js
                                            :key="idWorkPeriodChart"
                                            class="chartjs-chart"
                                            v-bind="{ ...verticalchartJs }"
                                        ></vue3-chart-js>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div class="col-md-4">
                                <div class="card p-3" style=" border: 1px solid #09B67D; height: 248px;">
                                    <p class="fw-bold">Employment Status</p>
                                    <b-progress class="mt-1 rounded-5" style="height: 25px; background-color: #f4f4f4;">
                                        
                                        <!-- <b-progress-bar
                                                v-for="employee in employmentStatus"
                                                :key="employee.name"
                                                :value="employee.percentage"
                                                :style="{ backgroundColor: employee.color }"
                                        ></b-progress-bar> -->
                                        <b-progress-bar
                                            v-for="employee in employmentStatus"
                                            :key="employee.name"
                                            :value="employee.percentage"
                                            :style="{ backgroundColor: employee.color }"
                                        ></b-progress-bar>
                                    </b-progress>
                                    <div class="d-flex justify-content-between ">
                                        <p>0%</p>
                                        <p>100%</p>
                                    </div>
                                    <div class="">
                                        <div v-for="employee in employmentStatus" :key="employee.name" class="d-flex justify-content-between">
                                            <span>
                                            <span class="legend-box" :style="{ backgroundColor: employee.color }"></span>
                                            {{ employee.name }}
                                            </span>
                                            <span>{{ employee.count }} ({{ employee.percentage.toFixed(2) }}%)</span>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                        
                    </div>

                    <div class="col-12 ">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="card p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <p class="fw-bold">Performance Management</p>

                                    <div class="chartjs-wrapper-demo" style="margin-bottom: 10px;">
                                        <vue3-chart-js
                                            :key="idPerformanceChart"
                                            class="chartjs-chart"
                                            v-bind="{ ...linechartJs }"
                                        ></vue3-chart-js>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card p-3 rounded-3" style="border: 1px solid #09B67D;">
                                    <p class="fw-bold">Headcount & Turnover</p> 
            
                                    <div class="chartjs-wrapper-demo" style="width: 100%; height: 160px;">
                                        <vue3-chart-js
                                            :key="idTurnOverChart"
                                            class="chartjs-chart"
                                            v-bind="{ ...piechartJs }"
                                        ></vue3-chart-js>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                 

                </div>



            </div>


            
        </div>
        
    </div>

</template>

<style >
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right {

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: red; */
}

.btn-datefilter {
    background-color: #09B67D !important;
    border-radius: 15px !important;
    color: white !important;
}

.legend-box {
    display: inline-block;
    width: 12px;  /* Adjust size as needed */
    height: 12px; /* Adjust size as needed */
    margin-right: 8px;
    border-radius: 2px; /* Optional: Make it slightly rounded */
}

.dropdown-menu {
  display: block;
  position: absolute;
}

/* .input-groupicon {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    width: 100%;
}

.input-groupicon .picker {
    flex: 1;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    width: 100%;
}

.input-groupicon .addonset {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.input-groupicon .addonset img {
    width: 18px;
    height: 18px;
} */


/* .card {
  text-align: center;
} */

</style>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage } from "@/assets/js/function";

import { onMounted, ref } from "vue";
import Chart from "chart.js/auto";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { verticalchartJs, piechartTurnover, linechartPerformance, genderChart } from "./data";
import { DatePicker } from "ant-design-vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';


// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css';


// Register the plugin globally
Chart.register(ChartDataLabels);

export default {
    components: {
        Vue3ChartJs,
        // Datepicker,
    },

    data() {
        return {
            verticalchartJs: verticalchartJs,
            piechartJs: piechartTurnover,
            linechartJs: linechartPerformance,
            genderChart: genderChart,

            idGenderChart: 0,
            idWorkPeriodChart: 0,
            idTurnOverChart: 0,
            idPerformanceChart: 0,

            donutAreaChart: {
                donutchart: {
                    chart: {
                        id: "vuechart-example",
                    },

                    chart: {
                        type: 'pie',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        dataLabels: {
                            enabled: true,
                        },
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'right',

                    },
                    labels: ['Present', 'Late', 'Absent', 'Annual Leave', 'Leave'],
                    colors: ['#08B67E', '#FFC42F', '#FA547C', '#5271FF', '#ACCE25'],
                },
                series: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }

                }],
            },
            data: [
                // {
                //     id: 1,
                //     Sno: "1",
                //     lampiran: "product22.jpg",
                //     judul: "[MENUNGGU] - Pengajuan IZIN ",
                //     tanggal: "18 Jan 2024",
                //     token: ""
                //     // Price: "$891.2",
                // },

            ],
            columns: [
                {
                    title: "No",
                    dataIndex: "Sno",
                    sorter: {
                        compare: (a, b) => {
                            a = a.Sno.toLowerCase();
                            b = b.Sno.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Form",
                    dataIndex: "judul",
                    key: "id",
                    sorter: {
                        compare: (a, b) => {
                            a = a.judul.toLowerCase();
                            b = b.judul.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Price",
                //     dataIndex: "Price",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.Price.toLowerCase();
                //             b = b.Price.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
            ],
            columns2: [],
            data2: [],
            absensiList: [],
            companyList: [],
            genderList: [],
            turn_over: [],
            performance: [],
            work_period: [],
            job_level: [],
            employee_status: [],

            branchList: [],

            periode: new Date(),
            
            // value: "",

            jobLevels: [
                { name: "Direktur", count: 0, color: "#B3D8FF" }, // Light blue
                { name: "Manager", count: 0, color: "#FFD24C" }, // Yellow
                { name: "Staff", count: 0, color: "#09B67D" }, // Green
                { name: "Supervisor", count: 0, color: "#FF9500" }, // Orange
            ],

            employment: [
                { name: "Permanent", count: 0, color: "#09B67D" }, // Green
                { name: "Contract", count: 0, color: "#FFD24C" }, // Yellow
                { name: "Probation", count: 0, color: "#B3D8FF" }, // Light blue
                { name: "Internship", count: 0, color: "#FF9500" }, // Orange
            ],

            // companyList: [],
            config: "",
            api: "",
            showChart: false,

            //data
            companyselected: "",
            filter: 0,

            //token
            token: "",

            value: 45,
            max: 100,

            showChart: false,

        };
    },


    watch: {
        job_level: {
            handler() {
                this.mapJobLevels(); // Auto-update when API data changes
            },
            deep: true,
            immediate: true
        },

        employee_status: {
            handler() {
                this.mapJobLevels(); // Auto-update when API data changes
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        totalCount() {
            return this.jobLevels.reduce((sum, job) => sum + job.count, 0);
        },

        totalCountEmployment() {
            // return this.employment.reduce((sum, employment) => sum + employment.count, 0);
            if (!this.employee_status) return 0; // Prevent undefined errors

            return (
                (this.employee_status.permanent || 0) +
                (this.employee_status.contract || 0) +
                (this.employee_status.probation || 0) +
                (this.employee_status.internship || 0)
            );
        },

        jobLevelsWithPercentage() {
            if (this.totalCount === 0) return []; // Prevent division by zero
            // return this.jobLevels.map(job => ({
            //     ...job,
            //     percentage: (job.count / this.totalCount) * 100,
            // }));

            return [
                { name: "Direktur", count: this.job_level.direktur || 0, percentage: this.job_level.percent_direktur || 0, color: "#B3D8FF" },
                { name: "Manager", count: this.job_level.manager || 0, percentage: this.job_level.percent_manager || 0, color: "#FFD24C" },
                { name: "Supervisor", count: this.job_level.supervisor || 0, percentage: this.job_level.percent_supervisor || 0, color:  "#09B67D" },
                { name: "Staff", count: this.job_level.staff || 0, percentage: this.job_level.percent_staff || 0, color: "#FF9500" },
                { name: "Others", count: this.job_level.others || 0, percentage: this.job_level.percent_others || 0, color: "#A0A0A0" }
            ];
        },

        employmentStatus() {
            if (this.totalCountEmployment === 0) return []; 

            return [
                { name: "Permanent", count: this.employee_status.permanent || 0, percentage: this.employee_status.percent_permanent || 0, color: "#09B67D" }, // Green
                { name: "Contract", count: this.employee_status.contract || 0, percentage: this.employee_status.percent_contract || 0, color: "#FFD24C" }, // Yellow
                { name: "Probation", count: this.employee_status.probation || 0, percentage: this.employee_status.percent_probation || 0, color: "#B3D8FF" }, // Light blue
                { name: "Internship", count: this.employee_status.internship || 0, percentage: this.employee_status.percent_internship || 0, color: "#FF9500" }, // Orange
                { name: "Others", count: this.employee_status.others || 0, percentage: this.employee_status.percent_others || 0, color: "#A0A0A0" }

            ];
        },
    },


    mounted() {
        // Simulating API Fetch (Replace with actual API call)
        // setTimeout(() => {
        //     this.genderList = this.genderList; // Example API response
        //     this.updateGenderChart();
        // }, 2000);

        this.mapJobLevels();
        
    },

    methods: {

        randomValue() {
            this.value = Math.random() * this.max
        },

        goToApproval() {
            if(this.filter != 0){
                goPage('approval')
            }else {
                AlertPopup("error", "", 'Branch harus dipilih terlebih dahulu!', 1500 , false)
            }
        },

        

        updateGenderChart() {
            // Dynamically update chart data when genderList changes
            this.genderChart.data.datasets[0].data = [
                this.genderList.male || 0,
                this.genderList.female || 0
            ];
        },

        mapJobLevels() {
            if (this.job_level) {
                this.jobLevels = [
                    { name: "Direktur", count: this.job_level.direktur || 0, percentage: this.job_level.percent_direktur || 0, color: "#B3D8FF"},
                    { name: "Manager", count: this.job_level.manager || 0, percentage: this.job_level.percent_manager || 0, color: "#FFD24C" },
                    { name: "Supervisor", count: this.job_level.supervisor || 0, percentage: this.job_level.percent_supervisor || 0, color: "#09B67D" },
                    { name: "Staff", count: this.job_level.staff || 0, percentage: this.job_level.percent_staff || 0, color: "#FF9500"}
                    // { name: "Others", count: this.job_level.others || 0, percentage: this.job_level.percent_others || 0, color:  }
                ];
            }
        },

        mapEmployeeStatus() {
            if (this.employee_status) {
                this.employement = [
                    { name: "Permanent", count: this.employee_status.permanent || 0, percentage: this.employee_status.percent_permanent || 0, color: "#09B67D" }, // Green
                    { name: "Contract", count: this.employee_status.contract || 0, percentage: this.employee_status.percent_contract || 0, color: "#FFD24C" }, // Yellow
                    { name: "Probation", count: this.employee_status.probation || 0, percentage: this.employee_status.percent_probation || 0, color: "#B3D8FF" }, // Light blue
                    { name: "Internship", count: this.employee_status.internship || 0, percentage: this.employee_status.percent_internship || 0, color: "#FF9500" }, // Orange
                        
                ];
            }
        },

        

        index() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 10);


            formData.append("periode", formattedDate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_absensi';
            } else {
                this.api = base_url + 'dashboard_absensi';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.showChart = false;
                        if (response.data.data.hadir == 0 && response.data.data.terlambat == 0 && response.data.data.absen == 0) {
                            this.showChart = false;
                        } else {
                            this.donutAreaChart.series = [response.data.data.hadir, response.data.data.terlambat, response.data.data.absen]
                            this.showChart = true;
                        }

                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        dashboard_notif() {
            ShowLoading();
            let formData = new FormData();

            formData.append("jenis", 'pengajuan');

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_notif_new';
            } else {
                this.api = base_url + 'dashboard_notif_new';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data.forEach((item, index) => {
                            if (item.token != '') {
                                let item_prepare = { ...item, Sno: (index + 1) + "", id: index };
                                this.data.push(item_prepare);
                            }

                        });
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        dashboard_index() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            // const formattedDate = currentDate.toISOString().slice(0, 10);
            var dateObj = new Date(this.periode);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits
            var formattedDate = `${year}-${month}-${day}`;

            formData.append("periode", formattedDate);

            if( this.filter == 0){

                formData.append("filter", '');

            }else {
                formData.append("filter", this.filter);

            }

            // console.log('ini filter :', this.filter)

            // for (let [key, value] of formData.entries()) {
            //     console.log(key, value);
            // }
            // CloseLoading()
            // return true

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_index2';
            } else {
                this.api = base_url + 'dashboard_index2';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.status == true) {
                        localStorage.setItem('nama_user', response.data.data.fullname);
                        localStorage.setItem('tipe_user', response.data.data.tipe);

                        // console.log('data absensi', response.data.data.data_dashboard)

                        this.absensiList = response.data.data.data_dashboard.absensi;
                        this.companyList = response.data.data.data_dashboard.company;
                        this.genderList = response.data.data.data_dashboard.jenis_kelamin;
                        this.turn_over = response.data.data.data_dashboard.turn_over;
                        this.performance = response.data.data.data_dashboard.performance;
                        this.job_level = response.data.data.data_dashboard.job_level;
                        this.work_period = response.data.data.data_dashboard.work_period;
                        this.employee_status = response.data.data.data_dashboard.employee_status;

                        // console.log('data jenis kelamin :', response.data.data.data_dashboard.jenis_kelamin)

                        // Check if genderList has data
                        if (this.genderList) {
                            // Update the genderChart dataset dynamically
                            this.genderChart.data.datasets[0].data = [
                                this.genderList.male || 0,
                                this.genderList.female || 0,
                                
                            ];

                            // console.log('data chart', this.genderList)
                            // Update the labels dynamically if needed
                            this.genderChart.data.labels = ["Male", "Female"];
                        }

                        // turn over
                        if (this.turn_over) {
                            
                            this.piechartJs.data.datasets[0].data = [
                                this.turn_over.in || 0,
                                this.turn_over.out || 0,
                                
                            ];

                            // console.log('data chart pie', this.turn_over)
                            // Update the labels dynamically if needed
                            this.piechartJs.data.labels = ["In", "Out"];
                        }

                        // performance
                        if (this.performance && this.linechartJs) {
                            
                            this.linechartJs.data.datasets[0].data = this.performance.present || Array(12).fill(0);
                            this.linechartJs.data.datasets[1].data = this.performance.late || Array(12).fill(0);

                            // console.log('data chart performance', this.performance)
                            // Update the labels dynamically if needed
                            this.linechartJs.data.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];;
                        }

                        // work period (lenght_of_service)
                        if (this.work_period) {
                            
                            this.verticalchartJs.data.datasets[0].data = [
                                this.work_period["<1y"] || 0,
                                this.work_period["1-2y"] || 0,
                                this.work_period["3-5y"] || 0,
                                this.work_period[">6y"] || 0  
                            ];

                            // console.log('data chart period', this.work_period)
                            // Update the labels dynamically if needed
                            this.verticalchartJs.data.labels = ['< 3 Yr', '1 - 3 Yr', '3 - 5 Yr', '5 - 10 Yr'];
                        }
                        
                        this.branchList = []
                        this.branchList.push({id: 0, text: "All Branch", })
                        response.data.data.branch.forEach((item) => {
                            let item_prepare = { id: item.token_refresh, text: item.company_name, };
                            this.branchList.push(item_prepare);
                        });

                        // console.log(this.filter)

                        if(this.filter != 0){

                            Cookies.set("token_refresh", response.data.data.token_refresh);
                            Cookies.set("selectedBranch", this.filter);

                            
                        }else {
                            let token_utama = Cookies.get("token_refresh_utama")
                            Cookies.set("token_refresh", token_utama);

                        }

                        this.idGenderChart++;
                        this.idWorkPeriodChart++;
                        this.idTurnOverChart++;
                        this.idPerformanceChart++;

                        // this.showChart = true;
                        

                        

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },

        dashboard_index_old() {
            ShowLoading();
            // let formData = new FormData();
            // const currentDate = new Date();
            // // const formattedDate = currentDate.toISOString().slice(0, 10);
            // var dateObj = new Date(currentDate);

            // Format the date manually (e.g., yyyy-mm-dd)
            // var year = dateObj.getFullYear();
            // var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            // var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits
            // var formattedDate = `${year}-${month}-${day}`;

            formData.append("periode", formattedDate);

            // if (process.env.NODE_ENV === "development") {
            //     this.api = base_url + 'dashboard_index';
            // } else {
            //     this.api = base_url + 'dashboard_index';
            // }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {
                        // localStorage.setItem('nama_user', response.data.data.fullname);
                        // localStorage.setItem('tipe_user', response.data.data.tipe);

                        // response.data.data.company.forEach((item) => {
                        //     let item_prepare = { id: item.token_refresh, text: item.company_name, };
                        //     this.companyList.push(item_prepare);
                        // });

                        // set company
                        // const index_company = this.companyList.findIndex((record) => record.text === response.data.data.nama_company);

                        // if (index_company > -1) {
                        //     this.companyselected = this.companyList[index_company].id;
                        // }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },

        setCompany() {
            Cookies.set("token_refresh", this.companyselected);
            this.token = this.companyselected;
            this.companyList = [];
            this.data = [];
            this.config = {
                headers: { Authorization: `Bearer ` + this.token },
                timeout: 30000,
            }

            this.index();
            this.dashboard_notif();
            this.dashboard_index();
        }


    },


    

    created() {
        this.token = Cookies.get("token_refresh");
        Cookies.set("token_refresh_utama", this.token);
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        let selectedBranch = Cookies.get("selectedBranch");

        if(selectedBranch != null || selectedBranch != undefined || selectedBranch != ''){
            this.filter = selectedBranch;
        }

        this.index();
        this.dashboard_notif();
        this.dashboard_index();
    }


};
</script>
