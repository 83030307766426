<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />

                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="../../../assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="../../../assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                                            @click="export_employee"
                                            class="d-flex flex-column justify-content-center  align-items-center "><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" width="25" /> <span
                                                class="text-black">
                                                excel
                                            </span>
                                        </a>

                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="sync"
                                            @click="sync_employee" v-if="isanviz != ''"
                                            class="d-flex flex-column justify-content-center align-items-center"><vue-feather
                                                type="repeat" class="text-success"></vue-feather>
                                            <span class="text-black">sync</span></a>

                                    </li>

                                    <!-- <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <!-- /Filter -->
                        <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="../../../assets/img/icons/search-whites.svg"
                                                            alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Filter -->
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'employee_id'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('../../../assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.employee_id }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'id'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('../../../assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'telepon'">
                                        <div class="productimgname">
                                            {{ record.telepon }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'tipe_kerja'">
                                        <div class="productimgname">
                                            {{ record.tipe_kerja }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'nama_departemen'">
                                        <div class="productimgname">
                                            <span v-if="record.nama_departemen == 0">-</span>
                                            <span v-else>{{ record.nama_departemen }}</span>

                                        </div>
                                    </template>
                                    <template v-if="column.key === 'jabatan'">
                                        <div class="productimgname">
                                            <span v-if="record.jabatan == 0">-</span>
                                            <span v-else>{{ record.jabatan }}</span>

                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <a class="text-primary me-3" href="javascript:void(0);"
                                            @click="mutasi(record.id); showModal('mutasi');">
                                            <vue-feather type="corner-up-right"></vue-feather>
                                        </a>
                                        <router-link class="me-3" to="editemployee" @click="edit(record);">
                                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id); showModal('terminate');">
                                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                                        </a>

                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Employee Terminate</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        <div class="form-group d-flex flex-column gap-2">
                            <div class="input-groupicon" style="z-index: 100;">
                                <datepicker v-model="tanggal_terminate" class="picker" :editable="true"
                                    :clearable="false" required />
                                <a class="addonset">
                                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                                </a>
                            </div>
                            <div>
                                <textarea class="form-control" placeholder="Write terminate reason in here"
                                    v-model="alasan_terminate" required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <!-- <button type="button" class="btn btn-sm btn-secondary" @click="hideModal2('terminate')">cancel</button> -->
                        <button type="submit" class="btn btn-sm btn-danger">Terminate</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade" id="mutasi" ref="mutasi" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Employee Transfer</h5>
                    <button type="button" class="close" @click="hideModal2('mutasi')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="mutasi_data">
                    <div class="modal-body">
                        <div class="form-group d-flex flex-column gap-2">
                            <!-- <div class="input-groupicon" style="z-index: 100;">
                                <datepicker v-model="tanggal_terminate" class="picker" :editable="true"
                                    :clearable="false" required />
                                <a class="addonset">
                                    <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                                </a>
                            </div> -->
                            <div>
                                <vue-select :options="tujuanMutasiList"
                                    :settings="{ settingOption: value, settingOption: value }"
                                    v-model="id_tujuan_mutasi" placeholder="Choose Location" required>
                                </vue-select>
                            </div>
                            <div>
                                <textarea class="form-control" placeholder="Write transfer reason in here"
                                    v-model="alasan_terminate" required />
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <!-- <button type="button" class="btn btn-sm btn-secondary" @click="hideModal2('mutasi')">cancel</button> -->
                        <button type="submit" class="btn btn-sm btn-danger">mutasi</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
    data() {
        return {
            filter: false,
            title: "Employee List",
            title1: "Manage your employee",
            items: [
                {
                    text: "Add New Employee",
                    addname: "/addemployee",
                },
            ],
            Product: ["Choose Product", "Macbook pro", "Orange"],
            Category: ["Choose Sub Category", "Computer"],
            Choose: ["Choose Category", "Computers", "Fruits"],
            Brand: ["Brand", "N/D"],
            Price: ["Price", "150.00"],

            //data
            data: [
            ],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Employee Id",
                //     dataIndex: "employee_id",
                //     key: "employee_id",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.employee_id.toLowerCase();
                //             b = b.employee_id.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Employee Id",
                    dataIndex: "employee_id2",
                    key: "employee_id2",
                    sorter: {
                        compare: (a, b) => {
                            a = a.employee_id2.toLowerCase();
                            b = b.employee_id2.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Name",
                    dataIndex: "nama",
                    key: "id",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama.toLowerCase();
                            b = b.nama.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Phone Number",
                    dataIndex: "telepon",
                    key: "telepon",
                    sorter: {
                        compare: (a, b) => {
                            a = a.telepon.toLowerCase();
                            b = b.telepon.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Work Type",
                    dataIndex: "tipe_kerja",
                    key: "tipe_kerja",
                    sorter: {
                        compare: (a, b) => {
                            a = a.tipe_kerja.toLowerCase();
                            b = b.tipe_kerja.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Department",
                    dataIndex: "nama_departemen",
                    key: "nama_departemen",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_departemen.toLowerCase();
                            b = b.nama_departemen.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Job Tittle",
                    dataIndex: "nama_jabatan",
                    key: "nama_jabatan",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_jabatan.toLowerCase();
                            b = b.nama_jabatan.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Shift",
                    dataIndex: "nama_shift",
                    key: "nama_shift",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_shift.toLowerCase();
                            b = b.nama_shift.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],


            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            id_tujuan_mutasi: "",

            isanviz: "",

            currentData: {},

            //filter
            query: "",

            tujuanMutasiList: [],
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {
                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            return this.data.filter(item =>
                item.nama.toLowerCase().includes(lowerCaseQuery) ||
                item.telepon.includes(this.query) ||
                item.tipe_kerja.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_departemen.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_jabatan.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_shift.toLowerCase().includes(lowerCaseQuery)
            );
        }
    },
    methods: {
        show_izitoast() {
            AlertBottom('tessst');
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },

        sync_employee() {
            ShowLoading();
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'applications/anviz_sync?appname=anviz';
            } else {
                this.api = base_url + 'applications/anviz_sync?appname=anviz';
            }

            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertPopup("success", "", response.data.data.message, 1500, false);
                    } else if (response.data.status == false) {
                        AlertPopup("error", "", response.data.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#08b77e",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'employees/index';
            } else {
                this.api = base_url + 'employees/index';
            }
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {
                        this.data = [];


                        response.data.data.forEach((item, index) => {

                            //departemen search name
                            const departemen_index = response.data.data_departemen.findIndex((record) => record.id == item.departemen);
                            let nama_departemen_index = "";
                            if (departemen_index > -1) {
                                nama_departemen_index = response.data.data_departemen[departemen_index].nama;
                            } else {
                                nama_departemen_index = '-';
                            }

                            // jabatan search name 
                            const jabatan_index = response.data.data_jabatan.findIndex((record) => record.id == item.jabatan);
                            let nama_jabatan_index = "";
                            if (jabatan_index > -1) {
                                nama_jabatan_index = response.data.data_jabatan[jabatan_index].nama;
                            } else {
                                nama_jabatan_index = '-';
                            }

                            // shift search name 
                            const shift_index = response.data.data_shift.findIndex((record) => record.id == item.shift_id);


                            let nama_shift_index = "";
                            if (shift_index > -1) {
                                nama_shift_index = response.data.data_shift[shift_index].nama_shift;
                            } else {
                                nama_shift_index = '-';
                            }

                            let item_prepare = {
                                ...item, no: (index + 1), nama_departemen: nama_departemen_index, nama_jabatan: nama_jabatan_index,
                                nama_shift: nama_shift_index
                            };
                            this.data.push(item_prepare);
                        });

                        this.isanviz = response.data.is_anviz;

                        //save shift to local
                        localStorage.setItem('shiftList', JSON.stringify(response.data.data_shift));
                        //save location to local
                        localStorage.setItem('locationList', JSON.stringify(response.data.data_lokasi));
                        //save departement to local 
                        localStorage.setItem('departemenList', JSON.stringify(response.data.data_departemen));
                        //save jabatan to local 
                        localStorage.setItem('jabatanList', JSON.stringify(response.data.data_jabatan));
                        // save pajak to local
                        localStorage.setItem('statusPajakList', JSON.stringify(response.data.data_status_pajak));
                        // save employee to local
                        localStorage.setItem('employeeList', JSON.stringify(response.data.data));

                        const perusahaan = response.data.data_perusahaan;

                        perusahaan.forEach((value, index) => {
                            const element = { id: value.id, text: value.nama_perusahaan }
                            this.tujuanMutasiList.push(element);
                        });


                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        export_employee() {
            ShowLoading();
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'employees/export';
            } else {
                this.api = base_url + 'employees/export';
            }
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Employee');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Employee' + ".xlsx");
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        edit(record) {
            localStorage.setItem('currentEmployee', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            // const tanggal = this.tanggal_terminate.toISOString().split('T')[0];
            var dateObj = new Date(this.tanggal_terminate);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits

            var tanggal = `${year}-${month}-${day}`;
            formData.append("tanggal", tanggal);
            formData.append("alasan", this.alasan_terminate);

            // for (let [key, value] of formData.entries()) {
            //   console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'employee_delete';
            } else {
                this.api = base_url + 'employee_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        mutasi_data() {


            ShowLoading();
            if (this.id_tujuan_mutasi == "") {
                AlertPopup("error", "", "please choose the location", 1500, false);
                return true;
            }
            let formData = new FormData();
            formData.append("id_employee", this.id_terminate);
            formData.append("id_company_tujuan", this.id_tujuan_mutasi);
            // const tanggal = this.tanggal_terminate.toISOString().split('T')[0];
            var dateObj = new Date(this.tanggal_terminate);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits

            var formattedDate = `${year}-${month}-${day}`;

            formData.append("tanggal", formattedDate);
            formData.append("alasan_mutasi", this.alasan_terminate);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'employees/mutasi';
            } else {
                this.api = base_url + 'employees/mutasi';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('mutasi');

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id) {
            this.id_terminate = id;
            this.tanggal_terminate = new Date();
        },
        mutasi(id) {
            this.id_terminate = id;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>