const basicAreaChart = {
  sline: {
    chart: {
      id: "vuechart-example",
    },

chart: {
type: 'bar',
height: 300,
stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
            enabled: true
        }
},

colors: ['#28C76F', '#EA5455'],
dataLabels: {
    enabled: false
},
responsive: [{
    breakpoint: 280,
    options: {
        legend: {
        position: '',
        offsetY: 40,
        }
    }
    }],
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
        },
        },
xaxis: {
    categories: [' Jan ', 'feb', 'march', 'april',
    'may', 'june' , 'july' , 'auguest'],
},
fill: {
    opacity: 1

},
  },
  series: [{
    name: 'Sales',
    data: [50, 45, 60, 70, 50, 45, 60, 70 ],
    }, {
    name: 'Purchase',
    data: [-21, -54, -45, -35, -21, -54, -45, -35]
    }],
};

const genderChart = {
    type: "bar",
    data: {
        labels: ["Male", "Female"],
        datasets: [
            {
            data: [0, 0],
            // data: genderData,
            backgroundColor: ["#FFDA35", "#09B67D"]
            }
        ]
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: { display: false },  // Hide grid for X-axis
                ticks: { font: { size: 14 },  autoSkip: false, maxRotation: 0, minRotation: 0, }, // Make labels bigger
                
                
            },
            y: {
                grid: { display: false, drawBorder: false }, // Hide grid for Y-axis
                ticks: { display: false }, // Hide Y-axis numbers
                
                
            }
        },
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true },
            datalabels: {
                color: 'white',  // Text color
                font: {
                  weight: 'bold',
                  size: 10  // Adjust size
                },
                // formatter: (value, ctx) => {
                //   let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                //   let percentage = (value / sum * 100).toFixed(0) + "%";
                //   return percentage;
                // }
              }
        }
    }
}

const verticalchartJs = {
  type: 'bar',
  data: {
      labels: ['< 3 Yr', '1 - 3 Yr', '3 - 5 Yr', '5 - 10 Yr'],
      datasets: [{
          data: [0, 0, 0, 0],
          backgroundColor: '#09B67D',
          borderWidth: 1,
          fill: true
      }]
  },
  options: {
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false
          },
          datalabels: {
            color: 'white',  // Text color
            font: {
              weight: 'bold',
              size: 10  // Adjust size
            },
            // formatter: (value, ctx) => {
            //   let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
            //   let percentage = (value / sum * 100).toFixed(0) + "%";
            //   return percentage;
            // }
          }
      },
      // legend: {
      //     display: false,
      //     labels: {
      //         display: false
      //     }
      // },
      scales: {
          yAxes: {
              stacked: true,
              ticks: {
                  beginAtZero: true,
                  fontSize: 9,
                  color: "#D9D9D9",
                  
                },
                grid: { display: true, drawBorder: false},
               
                

          },
          xAxes: {
              barPercentage: 0.5,
              stacked: true,
              ticks: {
                  fontSize: 11,
                  autoSkip: false, 
                  maxRotation: 0, 
                  minRotation: 0,
              },
              grid: { display: false,  drawBorder: false },
              

          }
      }
  }
};

const piechartTurnover = {
  type: 'pie',
  data: {
      labels: ['In', 'Out'],
      datasets: [{
          data: [0,0],
          backgroundColor: ['#6FD195', '#FF2525']
      }]
  },
  options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: { 
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 15, // Smaller color boxes
                padding: 30,  // Add space between legend items
                font: { size: 14 } // Increase text size for better visibility
            }
        },
        datalabels: {
            color: 'white',  // Text color
            font: {
              weight: 'bold',
              size: 10  // Adjust size
            },
            
          }
      },
      
      animation: {
          animateScale: true,
          animateRotate: true
      }
  },
//   plugins: [ChartDataLabels]

}

const linechartPerformance = {
  type: 'line',
  data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: "Present",
        data: [ ],
        borderColor: '#6FD195',
        borderWidth: 1,
        fill: true,
        backgroundColor: 'rgba(111, 209, 149, 0.2)',
        tension: 0.4 
      }, {
        label: "Late",
        data: [],
        borderColor: '#FF9500',
        borderWidth: 1,
        fill: true,
        backgroundColor: 'rgba(255, 149, 0, 0.2)',
        tension: 0.4
      }]
  },
  options: {
      maintainAspectRatio: false,
      plugins: { 
        legend: {
            display: true,
            position: 'top', // Place legend on top
            align: 'end'  
        },
        datalabels: {
            display: false,
            
          }
      },
      legend: {
          // display: false,
          // labels: {
          //     display: false
          // }
      },
      scales: {
          yAxes: {
              ticks: {
                  beginAtZero: true,
                  fontSize: 10,
                  max: 80
              }
          },
          xAxes: {
              ticks: {
                  beginAtZero: true,
                  fontSize: 11
              }
          }
      }
  }
};

export {
    basicAreaChart,
    verticalchartJs,
    piechartTurnover,
    linechartPerformance,
    genderChart,
  };
