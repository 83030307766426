<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />
                <!-- /product list -->
                <div class="card">
                    <div class="card-body pt-1">
                        <div class="table-top">

                            
                            <div class="search-set " style="margin-top: 10px !important; margin-bottom: 0px !important;">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>


                                
                            </div>
                            <div class="wordset">
                                <ul>
                                    <li class="" >
                                        <router-link class=" d-flex flex-column justify-content-between  align-items-center " to="importschedule" style="margin-top: 15px !important; margin-bottom: 0px !important;"><vue-feather
                                                type="upload" class="text-success"></vue-feather> <span class="text-black">Import</span></router-link>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"
                                            @click="export_schedule"
                                            class="d-flex flex-column justify-content-between align-items-center " style="margin-top: 10px !important;  margin-bottom: 0px !important;"><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" width="30" /> <span
                                                class="text-black">
                                                Excel
                                            </span>
                                        </a>

                                    </li>

                                    <li>
                                        <div class="form-group" style="margin-top: 10px !important; margin-bottom: 0px !important;">
                                            <div class="input-groupicon" style="z-index: 100;">
                                                <datepicker v-model="periode" class="picker" :editable="true"
                                                    :clearable="false" @update:modelValue="index" />
                                                <a class="addonset">
                                                    <i class='bx bx-calendar'></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    

                                </ul>
                            </div>

                            
                            
                            <!-- <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive mt-0">
                            <a-table :columns="columns" :data-source="filteredData">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <!-- <template v-if="column.key === 'deskripsi'">
                                        <div class="productimgname">
                                            {{ shortenDescription(record.deskripsi) }}
                                        </div>


                                    </template> -->

                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <router-link class="me-3" to="editschedule" @click="edit(record);">
                                            <img src="@/assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id, record.nama_departemen); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Are u sure want to delete this data?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import XLSX from "xlsx/dist/xlsx.full.min.js";



export default {
    data() {
        return {
            filter: false,
            title: "Schedule List",
            title1: "manage schedule",
            items: [
                {
                    text: "Add Schedule ",
                    addname: "/addschedule",
                },
            ],

            periode: new Date(),

            //data
            data: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Date",
                    dataIndex: "tanggal",
                    key: "tanggal",
                    sorter: {
                        compare: (a, b) => {
                            a = a.tanggal.toLowerCase();
                            b = b.tanggal.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Clock in",
                    dataIndex: "jam_masuk",
                    key: "jam_masuk",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jam_masuk.toLowerCase();
                            b = b.jam_masuk.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Clock out",
                    dataIndex: "jam_keluar",
                    key: "jam_keluar",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jam_keluar.toLowerCase();
                            b = b.jam_keluar.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Employee",
                    dataIndex: "nama_employee",
                    key: "nama_employee",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_employee.toLowerCase();
                            b = b.nama_employee.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                {
                    title: "Whatsapp",
                    dataIndex: "telepon",
                    key: "telepon",
                    sorter: {
                        compare: (a, b) => {
                            a = a.telepon.toLowerCase();
                            b = b.telepon.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {

                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            return this.data.filter(item =>
                item.tanggal.toLowerCase().includes(lowerCaseQuery) ||
                item.jam_masuk.toLowerCase().includes(lowerCaseQuery) ||
                item.jam_keluar.toLowerCase().includes(lowerCaseQuery) ||
                item.nama_employee.toLowerCase().includes(lowerCaseQuery) ||
                item.telepon.toLowerCase().includes(lowerCaseQuery)
            );
        }
    },
    methods: {
        show_izitoast() {
            AlertBottom('tessst');
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },


        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        export_schedule() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            // const formattedDate = currentDate.toISOString().slice(0, 10);
            var dateObj = new Date(this.periode);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits
            var formattedDate = `${year}-${month}-${day}`;
            

            formData.append("periode", formattedDate);

            // for (let [key, value] of formData.entries()) {
            //     console.log(key, value);
            // }
            // CloseLoading()
            // return true

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'owners/schedule/export';
            } else {
                this.api = base_url + 'owners/schedule/export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data.data)
                    if (response.data.status == true) {
                        const fileUrl = response.data.data.file_report;

                        if (!this.periode) {
                            console.error("Error: this.periode is undefined or null");
                            return;
                        }

                        if (!fileUrl) {
                            // console.error("Error: file_report URL is missing!");
                            AlertPopup("error", "", "Download link not found", 1500, false);
                            CloseLoading();
                            return;
                        }

                        
                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.setAttribute("download", "Schedule.xlsx"); // You can change the filename
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        // response.data.data = this.addNumberToObjects(response.data.data);
                        // for (var i = 0; i < response.data.data.length; i++) {
                        //     delete response.data.data[i]["id"];
                        //     delete response.data.data[i]["created_at"];
                        //     delete response.data.data[i]["updated_at"];
                        // }

                        // // Create a new workbook
                        // const wb = XLSX.utils.book_new();
                        // // Create a new worksheet
                        // const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // // Add the worksheet to the workbook
                        // XLSX.utils.book_append_sheet(wb, ws, 'Schedule');
                        // // Convert the workbook to an XLSX file and download it
                        // XLSX.writeFile(wb, 'Schedule' + ".xlsx");
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#08b77e",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },
        shortenDescription(description) {
            if (description.length > 35) {
                return description.substring(0, 35) + '...';
            } else {
                return description;
            }
        },
        index() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            // const formattedDate = currentDate.toISOString().slice(0, 10);
            var dateObj = new Date(this.periode);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits
            var formattedDate = `${year}-${month}-${day}`;
            

            formData.append("periode", formattedDate);

            // for (let [key, value] of formData.entries()) {
            //     console.log(key, value);
            // }
            // CloseLoading()
            // return true


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'owners/schedule/index';
            } else {
                this.api = base_url + 'owners/schedule/index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        //save shift to local
                        localStorage.setItem('needData1', JSON.stringify(response.data.data_employee));
                        localStorage.setItem('needData2', JSON.stringify(response.data.data_shift));

                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            // formData.append("nama", this.nama_terminate);


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'owners/schedule/delete';
            } else {
                this.api = base_url + 'owners/schedule/delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id, nama) {
            this.id_terminate = id;
            this.nama_terminate = nama;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>